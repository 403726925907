:root {
    --color-negro:rgb(82, 82, 81);
    --color-bordo:#DA442D;
    --color-dorado:rgb(226,214,130);
    --color-verde:#80c7bc;
    --color-celeste:#00aae4;
  }

.App {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("./assest/backgroung_gray.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
   
}

.container-login {
    width: 100%;
    background: #fff;
    margin-top: 50px;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
    border-radius: 5px;
    padding: 30px;
}

.card-img {
    width: 100px;
}

.card a {
    text-decoration: none;
}

.navbar-light .navbar-nav .nav-link {
    font-size: 16px;
}

.spinner {
    display: flex;
    justify-content: center;
}

.titulo_entrada {
    background-image: url("./assest/fondo.jpg");
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
}

.titulo_entrada h1 {
    padding: 30px;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 50px;
    color: rgb(82, 82, 81, 1);
}
.form-pass a{
    text-decoration: none;
    color: darkgreen;
}
.form-pass a:hover{
    
    color: rgb(159, 33, 38);
}
.icon{
    position: relative;
}

.icon__position {
    top: 30px;
    position: absolute;
    cursor: pointer;
    display: inline;
    right: 5px;
}

.button_row {
    justify-content: center;
}

.button_col {
    justify-content: center;
    /* align-items: flex-start;8*/
    display: flex;
    height: 80vh;
}

.botones {
    justify-content: center;
    height: 80vh;
    display: flex;
}

.botones_bienvenida {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button_col:first-child {
    align-items: center;
}

.button_col:last-child {
    align-items: center;
}


td .button-container{
    height: fit-content;
    
}
td .button-container button{
     background-color: transparent;
     border-color: transparent;
}
td .button-container button svg{
    fill: var(--color-celeste);
    width: 2rem;
    height: 2rem;
}
.comercio_container{
    background-image: url("./assest/backgroung_gray.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 20px;
    min-height: 100vh;
}

@media (max-width: 770px) {
    .button_col:first-child {
        align-items: flex-end;
        height: 40vh;
    }
    .button_col:last-child {
        align-items: flex-start;
        height: 40vh;
    }
}

.comercio_container h2{
    text-align: center;
    font-size: 2.5rem;
}

.asociado_container{
    margin-top: 40px;
}