.card_ddjj{
    width: 98%;
    border-radius: 10px;
    margin: 0 auto 30px auto;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
    box-sizing: border-box;
    margin-top: 40px;
   
}

.card_ddjj__header{
    text-transform: uppercase;
    text-align: center;
    padding: 2rem;
    
}
.card_ddjj__body{
    text-transform: uppercase; 
    margin: 30px 0 0 10px;
   padding-bottom: 2rem;
    
}
.card_ddjj__body h3{
    display: flex;
   flex-wrap: wrap;
   justify-content: flex-start;
}
.ddjj_titulo {
  font-weight: 900;
   flex-basis: 250px;
}
.card_ddjj__body h3 :first-child{
    font-weight: 900;
   flex-basis: 250px;
  }
  .card_ddjj__body h3 :last-child{
    font-weight: 300;
    flex-grow: 1;
  }
  .card_ddjj__body h3 :last-child input,textarea{
    width: 90%;
  }
.card_ddjj__body h3 :last-child input:focus{
    background-color: var(--color-dorado);
    opacity: .5;
 }

 .card_ddjj__body h3 :last-child textarea:focus{
    background-color: var(--color-dorado);
    opacity: .5;
   
 }
 .card_ddjj__footer{
   display:flex;
   justify-content: center;
   gap: 10px;
   flex-wrap: wrap;
   
   
 }
 .card_ddjj__footer  .btnn{
  text-transform: uppercase;
 }