.button-container {
    height: 100px;
    display: grid;
    place-items: center;
    align-items: center;
}

.btnn {
    font-size: 1.25em;
    text-decoration: none;
    text-transform: capitalize;
    background-color: rgba(218, 48, 43, .8);
    padding: 8px 46px;
    border-radius: 0;
    border-color: transparent;
    color: #fff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: color 0.3s;
    min-width: 250px;
}

.btnn::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(218, 48, 43, 1);
    border-radius: inherit;
    top: 0;
    left: 0;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.4s cubic-bezier(0.4, 1.7, 0.5, 0.6);
}

.btnn:hover {
    color: #fff;
}

.btnn:hover::before {
    transform: scaleX(1)
}